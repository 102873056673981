//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-504:_6308,_6304,_4512,_6480,_8956,_2476,_5860,_688;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-504')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-504', "_6308,_6304,_4512,_6480,_8956,_2476,_5860,_688");
        }
      }catch (ex) {
        console.error(ex);
      }